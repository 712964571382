import React from 'react';

import { FormMultiSelect, IFormMultiSelectProps } from 'helpers/form';

import { countryOptions } from './utils';

export const FormCountries: React.FC<IFormMultiSelectProps> = (props) => {
  const { required, placeholder = 'Select Countries', label = 'Countries', name, ...other } = props;

  return (
    <FormMultiSelect
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      {...other}
      options={countryOptions}
    />
  );
};
