import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { ActionIcon, Button, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { EyeOff, EyeOn, Lock, User } from 'blueprint5-icons';
import cx from 'classnames';

import { redirectAfterLogin } from 'utils/storage';
import { useGetSsoLink } from 'utils/hooks';
import { MantineIcon } from 'utils/ui/icon';
import { Intent } from 'utils/ui';
import { FormInput, useForm } from 'helpers/form';
import { Footer } from 'components/footer';
import { ToastError } from 'components/toast';
import { Globals } from 'types';
import { login } from 'store/session-store';
import { Routes } from 'utils/routes';

import './style.scss';

export interface ILoginFields {
  email: string;
  password: string;
}

const initialValues: ILoginFields = {
  email: '',
  password: '',
};

const Login: React.FC<{}> = observer(() => {
  const { toastStore } = useStore();
  const navigate = useNavigate();
  const [showSSO, setSSOLogin] = React.useState<boolean>(true);
  const [defaultLogin, setDefaultLogin] = React.useState<boolean>(false);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const signIn = async (data: ILoginFields, valid: boolean): Promise<void> => {
    if (!valid) {
      return;
    }

    const { email, password } = data;

    try {
      const session = await login(email, password);

      if (session?.mfa_check_required) {
        navigate(`${Routes.TWO_FACTOR_AUTH}`);
        return;
      }

      redirectAfterLogin(navigate);
      toastStore.clearAll();
    } catch (err) {
      toastStore.error(<ToastError error={err} placeholder="Something went wrong" />);
    }
  };

  const { formData, handlers, valid, onSubmit } = useForm<ILoginFields>(initialValues, 'um.session', signIn);
  const { ssoLink } = useGetSsoLink();

  const togglePasswordVisibilityField = (): void => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    formData.email.value?.match(/@mediafellows.com/) || defaultLogin ? setSSOLogin(false) : setSSOLogin(true);
  }, [formData.email.value, defaultLogin]);

  const setToMaintainceMode = (): void => {
    setSSOLogin(false);
    setDefaultLogin(true);
  };

  return (
    <div className={`page--login ${Globals.MODE_DARK_CLASS}`}>
      <div className={`login`}>
        <div className="login__head">
          <Title order={3}>Sign in</Title>
        </div>

        <div className="login__line" />

        <div className="login__content">
          <form onSubmit={onSubmit}>
            <FormInput
              placeholder="Type your Email here"
              leftIcon={<MantineIcon icon={<User />} />}
              name="email"
              type="email"
              label="Email"
              {...formData.email}
              {...handlers}
            />

            {!showSSO && (
              <>
                <FormInput
                  placeholder="Type your Password here"
                  leftIcon={<MantineIcon icon={<Lock />} />}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  rightElement={
                    <ActionIcon variant="default" onClick={togglePasswordVisibilityField}>
                      <MantineIcon icon={showPassword ? <EyeOff /> : <EyeOn />} />
                    </ActionIcon>
                  }
                  label="Password"
                  {...formData.password}
                  {...handlers}
                />

                <div className="login__resetlink mb-3 mt-2">
                  <Link to={`${Routes.RESET_PASSWORD}`}>Forgot Password?</Link>
                </div>
              </>
            )}

            {showSSO && (
              <div className="login__resetlink mb-1 mt-1">
                <Link to="#" onClick={setToMaintainceMode}>
                  Maintenance Login
                </Link>
              </div>
            )}

            <div className="d-flex justify-content-between align-items-center">
              {!showSSO && (
                <>
                  <Button size="md" disabled={!valid} variant={Intent.PRIMARY} type="submit">
                    Sign In
                  </Button>
                  <div>or</div>
                </>
              )}

              <Button
                fullWidth={showSSO}
                className={cx(`sso-link`, { 'login-sso': showSSO })}
                component="a"
                size="md"
                variant={showSSO ? 'primary' : 'default'}
                href={ssoLink + '?username=' + formData.email.value}
              >
                Sign in with SSO
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default Login;
