import { IAssetCustomRowProps } from 'components/asset/asset-edit-form/sections/custom-row';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import React from 'react';

import { IAsset, IMm3Asset } from 'types';
import { withAssetVersion } from 'utils/asset';

export const CustomAssetMetadataRow: React.FC<{ asset: IAsset | IMm3Asset }> = () => {
  return <></>;
};

type ICustomAssetMetadataEditRowProps<T extends {} = {}> = React.FC<IAssetCustomRowProps<T>>;

export const CustomOldAssetMetadataEditRow: ICustomAssetMetadataEditRowProps = () => {
  return <></>;
};

export const CustomMm3AssetMetadataEditRow: React.FC<
  Pick<IUseMm3FormReturn<IMm3Asset>, 'formData' | 'handlers'>
> = () => {
  return <></>;
};

export const CustomAssetMetadataEditRow = withAssetVersion(
  CustomOldAssetMetadataEditRow,
  CustomMm3AssetMetadataEditRow,
);

export const CustomSettingsRow: React.FC<{ asset: IMm3Asset }> = () => {
  return <></>;
};

export const CustomSettingsEditRow: React.FC<Pick<IUseMm3FormReturn<IMm3Asset>, 'formData' | 'handlers'>> = () => {
  return <></>;
};

export const CustomAboutRow: React.FC<{ asset: IMm3Asset }> = () => {
  return <></>;
};

export const CustomAboutEditRow: React.FC<Pick<IUseMm3FormReturn<IMm3Asset>, 'formData' | 'handlers'>> = () => {
  return <></>;
};

export const CustomSpecificationEditRows: React.FC<
  Pick<IUseMm3FormReturn<IMm3Asset>, 'formData' | 'handlers'>
> = () => {
  return <></>;
};

export const CustomIdentifierEditRows: React.FC<Pick<IUseMm3FormReturn<IMm3Asset>, 'formData' | 'handlers'>> = () => {
  return <></>;
};

export const CustomMetadaSection: React.FC<{ asset: IMm3Asset }> = () => {
  return <></>;
};

export const CustomMetadaEditSection: React.FC<{ asset: IMm3Asset; form: IUseMm3FormReturn<IMm3Asset> }> = () => {
  return <></>;
};
