import React from 'react';

import { FormChecklist } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IProduct } from 'types';

export const ProductFormAdditionalFields: React.FC<{ productForm: IUseMm3FormReturn<IProduct> }> = ({
  productForm: { formData, handlers },
}) => {
  if (!formData?.permissions?.options?.length) {
    return <></>;
  }

  return <FormChecklist name="permissions" label="Permissions" large {...formData?.permissions} {...handlers} />;
};
