import React from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormDate, FormInput } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

import { maxDate, minDate } from 'components/product-edit-sections/utils';
import { PmLgtLayer3MotionPictureStandard } from '@mediafellows/mm3-types';

interface IProductEditDistributionProps {
  layerForm: IUseMm3FormReturn<PmLgtLayer3MotionPictureStandard>;
}

export const ProductEditDistribution: React.FC<IProductEditDistributionProps> = ({ layerForm }) => {
  const { formData, handlers } = layerForm;

  return (
    <FormSectionWrapperV2 className="create_product__form" gridTemplateColumns="1fr" title="Distribution">
      <FormInput
        name="meta.broadcaster"
        key="broadcaster"
        label="Broadcaster"
        {...formData.meta?.broadcaster}
        {...handlers}
      />
      <FormDate
        name="meta.original_release_date"
        label="Original Release Date"
        {...formData.meta?.original_release_date}
        {...handlers}
        minDate={minDate}
        maxDate={maxDate}
        emptyValue={null}
        large
      />
      <FormInput name="meta.network" key="network" label="Network" {...formData.meta?.network} {...handlers} />
      <FormInput
        name="meta.gross_box_office"
        key="gross_box_office"
        label="Box Office"
        {...formData.meta?.gross_box_office}
        {...handlers}
      />
      <FormInput name="meta.rating" key="rating" label="Rating" {...formData.meta?.rating} {...handlers} />
    </FormSectionWrapperV2>
  );
};

export default ProductEditDistribution;
