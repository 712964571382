import React from 'react';
import { observer } from 'mobx-react-lite';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormCategories, FormHtmlInput, FormInput } from 'helpers/form';
import { IProductEditFormProps } from 'components/product-edit-sections/utils';

export const ProductEditAboutSection: React.FC<IProductEditFormProps> = observer(({ layerForm }) => {
  const { formData, handlers } = layerForm;

  return (
    <FormSectionWrapperV2 className="product-create_html-editors-container" title="About">
      <FormInput className="title" name="title" key="title" label="Title" {...formData.title} {...handlers} />

      <FormInput
        className="subtitle"
        name="meta.subtitle"
        key="subtitle"
        label="Subtitle"
        {...formData.meta?.subtitle}
        {...handlers}
      />

      <FormCategories
        name="meta.category_ids"
        key="category_ids"
        label="Genres"
        {...formData.meta?.category_ids}
        {...handlers}
        className="categories"
        large
      />
      <FormHtmlInput
        className="logline"
        name="meta.logline"
        key="logline"
        label="Logline"
        {...formData.meta?.logline}
        {...handlers}
        large
      />

      <FormHtmlInput
        className="synopsis"
        name="meta.synopsis"
        key="synopsis"
        label="Synopsis"
        {...formData.meta?.synopsis}
        {...handlers}
        large
      />

      <FormHtmlInput
        className="long_synopsis"
        name="meta.long_synopsis"
        key="long_synopsis"
        label="Long Synopsis"
        {...formData.meta?.long_synopsis}
        {...handlers}
        large
      />

      <FormHtmlInput
        className="additional_information"
        name="meta.additional_information"
        key="additional_information"
        label="Additional Information"
        {...formData.meta?.additional_information}
        {...handlers}
        large
      />
    </FormSectionWrapperV2>
  );
});

export default ProductEditAboutSection;
