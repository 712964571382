import React, { useCallback, useMemo } from 'react';
import { Flex, Popover, Switch } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { IFormMultiSelectOption, FormMultiSelect, formatFormLabel } from 'helpers/form';

import { fetchRecipients, parseCCList, parseRecipients, validateEmail } from './utils';
import { MantineIcon } from 'utils/ui';

import { IRecommendationFormProps } from './types';
import { ISelection } from 'types';

const HintText: React.FC = () => {
  return (
    <div className="p-3">
      If login is required:
      <br />
      - Deleted, blocked or rejected recipients will be restored, unblocked or reactivated respectively.
      <br />- Recipients without active accounts will be (re-)invited.
    </div>
  );
};

export const RecommendProductsStepRecipients: React.FC<IRecommendationFormProps> = ({
  form: { formData, handlers, values },
}) => {
  const { onChange } = handlers;
  const [opened, { close, open }] = useDisclosure(false);
  const setRecipients = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      const { recipient_list, recipients, contact_selections } = parseRecipients(options);
      onChange({ recipient_list, recipients, contact_selections });
    },
    [onChange],
  );

  const setCCList = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      const cc_list = parseCCList(options);
      const cc_contact_selections = options.filter((e) => e.itemType === 'group') as ISelection[];

      onChange({ cc_list, cc_contact_selections });
    },
    [onChange],
  );

  const setBCCList = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      const bcc_list = parseCCList(options);
      const bcc_contact_selections = options.filter((e) => e.itemType === 'group') as ISelection[];

      onChange({ bcc_list, bcc_contact_selections });
    },
    [onChange],
  );

  const handleToggle = useCallback(
    (field: 'requires_login') => () => {
      handlers.onChange({ [field]: !values[field] });
    },
    [handlers, values],
  );

  const RecipientsLabel = useMemo(() => {
    return (
      <Flex gap="sm" className="w-100" align="center">
        <span>{formatFormLabel('Recipients', formData.recipients.required)}</span>
        <Flex align="center">
          <Switch
            size="md"
            label="Require Login"
            data-param="requires_login"
            onChange={handleToggle('requires_login')}
            checked={Boolean(values.requires_login)}
          />
          <Popover withArrow opened={opened} position="top">
            <Popover.Target>
              <span className="mx-2" onMouseEnter={open} onMouseLeave={close}>
                <MantineIcon icon="info-sign" size="14" />
              </span>
            </Popover.Target>
            <Popover.Dropdown maw={500}>
              <Flex direction="row">
                <HintText />
              </Flex>
            </Popover.Dropdown>
          </Popover>
        </Flex>
      </Flex>
    );
  }, [close, formData.recipients.required, handleToggle, open, opened, values.requires_login]);

  return (
    <div className="recommend-products-wizard__recipients-wrapper">
      <div className="recommend-products-wizard__recipients-container">
        <FormMultiSelect
          fetchValues={fetchRecipients}
          fitInParent
          name="recipients"
          omni
          allowNewItems
          onSelectedItemsChange={setRecipients}
          validateNewItem={validateEmail}
          required
          {...formData.recipients}
          onBlur={handlers.onBlur}
          className="recommend-products-wizard-recipients-field"
          labelComponent={RecipientsLabel}
          placeholder="Add Recipients"
        />
        <FormMultiSelect
          fetchValues={fetchRecipients}
          fitInParent
          name="cc_recipients"
          label="CC List"
          rawLabel
          omni
          allowNewItems
          onSelectedItemsChange={setCCList}
          validateNewItem={validateEmail}
          {...formData.cc_recipients}
          {...handlers}
        />
        <FormMultiSelect
          fetchValues={fetchRecipients}
          fitInParent
          name="bcc_recipients"
          label="BCC List"
          rawLabel
          omni
          allowNewItems
          onSelectedItemsChange={setBCCList}
          validateNewItem={validateEmail}
          {...formData.bcc_recipients}
          {...handlers}
        />
      </div>
    </div>
  );
};
