import React from 'react';
import FormSectionWrapperV2 from 'components/form-section-wrapper-v2/form-section-wrapper-v2';
import { FormInput } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

import { PmLgtLayer3MotionPictureStandard } from '@mediafellows/mm3-types';

interface IProductEditLegalSection {
  layerForm: IUseMm3FormReturn<PmLgtLayer3MotionPictureStandard>;
}

export const ProductEditLegalSection: React.FC<IProductEditLegalSection> = ({ layerForm }) => {
  return (
    <FormSectionWrapperV2 className="create_product__form" title="Legal">
      <FormInput
        name="meta.copyright"
        key="copyright"
        label="Copyright"
        {...layerForm.formData.meta?.copyright}
        {...layerForm.handlers}
      />
      <FormInput
        name="meta.copyright_notice"
        key="copyright_notice"
        label="Copyright Notice"
        {...layerForm.formData.meta?.copyright_notice}
        {...layerForm.handlers}
      />
      <FormInput
        name="meta.rights_holder"
        key="rights_holder"
        label="Rights Holder"
        {...layerForm.formData.meta?.rights_holder}
        {...layerForm.handlers}
        type={'string'}
      />
    </FormSectionWrapperV2>
  );
};
