import React from 'react';
import { useBasicStore } from 'store';
import get from 'lodash/get';

import { IFormData } from 'helpers/form/mm3';
import { IFieldData, IFieldHandlers, IFormHandlers } from 'helpers/form/types';
import { FormSelect } from 'helpers/form';

export interface IFormLanguageVersionProps<T> {
  formData: IFormData<T>;
  label?: string;
  name: string;
  large?: boolean;
  handlers: Omit<IFormHandlers<T>, 'onSetFields'>;
  isRemoved?: boolean;
}

export function FormLanguageVersion<T>(props: IFormLanguageVersionProps<T>): React.ReactElement | null {
  const { formData, name, handlers, large, isRemoved = false } = props;
  const { languagesDataOptions } = useBasicStore();

  if (isRemoved) {
    return <></>;
  }

  return (
    <>
      <FormSelect
        className="language_type"
        name={`${name}.language_type`}
        key="language_type"
        label="Language Type (per Version)"
        placeholder="Search Language Type (per Version)"
        {...(get(formData, `[${name}].language_type`) as unknown as IFieldData<string>)}
        {...(handlers as IFieldHandlers<unknown>)}
        large={large}
      />
      <FormSelect
        className="language_id"
        name={`${name}.language_id`}
        key="language_id"
        label="Language (per Version)"
        placeholder="Search Language (per Version)"
        {...(get(formData, `[${name}].language_id`) as unknown as IFieldData<string>)}
        {...(handlers as IFieldHandlers<unknown>)}
        options={languagesDataOptions}
        large={large}
      />
    </>
  );
}
