import React from 'react';
import { observer } from 'mobx-react-lite';

import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { InfoBoxV2 } from 'components/info-box-v2';
import { IProduct, IProductLayer } from 'types';

import {
  ProductEditAboutSection,
  ProductEditDistribution,
  ProductEditIdentifiersSection,
  ProductEditProductionSection,
  ProductEditLegalSection,
} from 'components/product-edit-sections';

interface IProductMetadataTabEditFormViewProps {
  layerForm: IUseMm3FormReturn<IProductLayer>;
  productForm: IUseMm3FormReturn<IProduct>;
  padded?: boolean;
}

export const ProductMetadataTabEditFormView: React.FC<IProductMetadataTabEditFormViewProps> = observer(
  ({ layerForm, productForm, padded = false }) => {
    return (
      <InfoBoxV2 padded={padded} className="product-metadata-tab__container" wrapperClassName="w-100 pt-3 pb-3">
        <InfoBoxV2 gridTemplateColumns="1fr" padded={false}>
          <ProductEditAboutSection layerForm={layerForm} productForm={productForm} />
          <ProductEditProductionSection layerForm={layerForm} productForm={productForm} />
        </InfoBoxV2>
        <InfoBoxV2 gridTemplateColumns="1fr" padded={false}>
          <ProductEditDistribution layerForm={layerForm} productForm={productForm} />
          <ProductEditLegalSection layerForm={layerForm} />
          <ProductEditIdentifiersSection layerForm={layerForm} productForm={productForm} />
        </InfoBoxV2>
      </InfoBoxV2>
    );
  },
);

export default ProductMetadataTabEditFormView;
