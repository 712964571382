import React from 'react';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormAccessLevel, FormInput, FormSelect, IUseFormReturn } from 'helpers/form';
import { IMobileAppSyncFormData } from './custom-types';

export const keysGeneral = ['name', 'access_level', 'app_market_allocation_type'];

export const MobileAppSyncFormGeneral: React.FC<{ form: IUseFormReturn<IMobileAppSyncFormData> }> = ({ form }) => {
  return (
    <FormSectionWrapperV2 gridTemplateColumns="1fr" padded={false}>
      <FormInput name="name" label="Name" {...form.formData.name} {...form.handlers} />
      <FormAccessLevel
        name="access_level"
        label="Access Level"
        large
        {...form.formData.access_level}
        {...form.handlers}
      />
      <FormSelect
        name="app_market_allocation_type"
        label="App Presentation Mode Section"
        large
        {...form.formData.app_market_allocation_type}
        {...form.handlers}
      />
    </FormSectionWrapperV2>
  );
};
