import { EqFilter, InFilter, NotExistFilter, RangeFilter, QFilter } from 'helpers/filters/types';
import { additionalFilters } from 'components/product-filters/additional-filters';
import { IFilters } from 'components/product-filters/types';

export const noParentFilter = (defaultValue = true): { no_parent: NotExistFilter } => ({
  no_parent: new NotExistFilter('parent_id', defaultValue),
});

export const getDefaultFilters = ({ showParentsOnlyFilter = true, parentFilterDefaultValue = true }): IFilters => {
  return {
    _: new QFilter(''),
    type: new InFilter('type', []),
    updated_at: new RangeFilter('updated_at', '', ''),
    created_at: new RangeFilter('created_at', '', ''),
    access_level: new InFilter('access_level', []),
    owner_id: new EqFilter('owner_id', ''),
    owner_organization_id: new EqFilter('owner_organization_id', ''),
    'default_layer.meta.year_of_production': new RangeFilter('default_layer.meta.year_of_production', 1896, 2040),
    'default_layer.meta.language_versions.language_id': new EqFilter(
      'default_layer.meta.language_versions.language_id',
      '',
    ),
    'default_layer.meta.category_ids': new InFilter('default_layer.meta.category_ids', []),
    division_ids: new InFilter('division_ids', []),
    ...(showParentsOnlyFilter ? noParentFilter(parentFilterDefaultValue) : undefined),
    ...additionalFilters,
  } as IFilters;
};

export const stats = [
  'access_level',
  'type',
  'default_layer.meta.category_ids',
  'default_layer.meta.language_versions.language_id',
  'division_ids',
  ...Object.keys(additionalFilters),
].join(',');
