import React from 'react';

import { Routes } from 'utils/routes';
import DateElement from 'components/date-list-element';

import { DetailsPageTabs, IProduct, IProductLayer } from 'types';
import AssetsMainClassificationLink from 'components/asset/assets-aggregation/product-assets';
import { formatLayerDuration } from 'utils/date';
import { formatField } from 'utils/general';
import { useLanguageVersionInfo } from 'utils/hooks';
import { placeholder, CategoriesInfo } from 'utils/ui';
import { MfxTooltip } from 'utils/ui/mfx-tooltip';
import { Classes } from 'utils/ui';

import './style.scss';

export const ProductListItemAttributes: React.FC<{ product: IProduct }> = ({ product }) => {
  const { default_layer, published_at, updated_at } = product;

  const layer = (default_layer || {}) as IProductLayer;
  const { meta } = layer;
  const { category_ids, product_state, resolution, year_of_production_text, year_of_production, language_versions } =
    meta || {};

  const languagesInfo = useLanguageVersionInfo(language_versions);

  return (
    <div className={`product-list-item-row__attributes ${Classes.TEXT_MUTED}`}>
      <MfxTooltip
        label={formatField(year_of_production_text || year_of_production) as string}
        position="top"
        className="product-list-item-row__production"
      />
      <MfxTooltip
        label={formatLayerDuration(layer) as string}
        position="top"
        className="product-list-item-row__duration"
      />
      <div className="product-list-item-row__shortcut text-nowrap">
        <AssetsMainClassificationLink
          baseUrl={`${Routes.PRODUCTS}/${product?.id}/${DetailsPageTabs.ASSETS}`}
          assets={product?.assets}
          fallback="—"
        />
      </div>
      <div className="text-truncate product-list-item-row__resolutions text-nowrap">
        {formatField(resolution) || '—'}
      </div>
      <div className="product-list-item-row__genre text-truncate text-nowrap">
        <CategoriesInfo category_ids={category_ids} />
      </div>
      <div className="text-truncate text-nowrap product-list-item-row__languages">{placeholder(languagesInfo)}</div>
      <div className="text-truncate text-nowrap product-list-item-row__state">{formatField(product_state)}</div>
      <DateElement date={updated_at} mfxIcon="updated-at" className="text-truncate product-list-item-row__updated" />
      <DateElement
        date={published_at}
        mfxIcon="published-at"
        className="text-truncate product-list-item-row__published"
      />
    </div>
  );
};
