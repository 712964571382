import { IGroup, IMarketingEvent } from 'types';
import { useBasicStore } from 'store';
import { countStringItems } from 'utils/general';

export const getAttendeesCount = (event?: Pick<IMarketingEvent, 'approved_emails'>): number =>
  countStringItems(event?.approved_emails);

export type IEventStatus = 'upcoming' | 'ongoing' | 'finished' | 'unknown';

export const getEventStatus = (event: IMarketingEvent): IEventStatus => {
  if (!event.is_timed_event) {
    return 'ongoing';
  }

  const { event_starts_at = '', event_ends_at = '' } = event;

  const now = new Date().getTime();
  const start = new Date(event_starts_at).getTime();
  const end = new Date(event_ends_at).getTime();

  if (isNaN(start) || isNaN(end)) {
    return 'unknown';
  }

  if (now < start) {
    return 'upcoming';
  }

  if (now > end) {
    return 'finished';
  }

  return 'ongoing';
};

export const useEventUrl = (event?: IMarketingEvent): string | null => {
  const { affiliation } = useBasicStore();
  const { primary_buyer_domain } = affiliation;

  if (!event) {
    return null;
  }

  if (!primary_buyer_domain) {
    return null;
  }

  return `${primary_buyer_domain.protocol}://${primary_buyer_domain.name}/events/${encodeURIComponent(event.purpose)}`;
};
export const useScreeningRoomUrl = (screening?: IGroup): string | null => {
  const { affiliation } = useBasicStore();
  const { primary_buyer_domain } = affiliation;

  if (!screening) {
    return null;
  }

  if (!primary_buyer_domain) {
    return null;
  }

  return `${primary_buyer_domain.protocol}://${primary_buyer_domain.name}/screening-details/${encodeURIComponent(
    screening.id,
  )}`;
};
