import { Model } from 'helpers/filters/types';
import { fetchRoles } from 'utils/apis/role';
import { chipmunk } from 'utils/chipmunk';

interface IObjects {
  id: number;
  name: string;
}

export function getOrganizations(): Promise<IObjects[]> {
  return chipmunk.run(async (chipmunk) => {
    // without params chipmunk is complaining. Probably the param marked as required
    const { objects } = await chipmunk.action(Model.ORGANIZATIONS, 'get', { params: { organization_ids: [] } });
    return objects;
  });
}

// re export to allow overwriting for affiliations only on this component (like itvstudios)
export const fetchPlatformRoles = fetchRoles;
