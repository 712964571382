import { Pages } from 'utils/actions/types';
import { IFeatureFlag, ISubFeatureFlag } from 'types';
import { Routes } from 'utils/routes';
import { ISidebarSection } from 'store/profile-store';

const featureFlags: IFeatureFlag = {
  isMm3Assets: false,
  showCastCrewFeature: true,
  showBasketsFeature: true,
  showCollectionsFeature: false,
  showScreeningRoomFeature: true,
  showEventFeature: false,
  showConferenceFeature: true,
  showRecommendationFeature: true,
  showAssetRecommendationsFeature: false,
  showAccessPrivilegeFeature: false,
  showMobileSelectionsFeature: true,
  showMeetingsFeature: true,
  showProducerPortalFeature: false,
  showDeliveryFeature: false,
  showMediaBenchFeature: false,
  showAccessGroupFeature: false,
  showGuildsFeature: false,
  showSmartGroupFeature: false,
  showOrderManagementFeature: false,
};

const subFeatureFlags: ISubFeatureFlag = {
  showNewsManageShowcaseAction: false,
  showEventLayout: false,
  showAutonomousShowcaseButtons: false,
  showCollectionImagesTab: false,
  showFutureEpisodesFeature: false,
  showListRecommendationOnWebsiteFeature: false,
  showRelatedProductsFeature: true,
  hasCustomClassifications: false,
};

export const flags = {
  ssoProvider: 'okta',
  ...featureFlags,
  ...subFeatureFlags,
};

export const mbImportAllowedRoles = [];

export const allowedRolesByRoute = {};

export const getShowPermissions = (page?: Pages): boolean => Boolean(page || true);

export const hideSideBarFlags: Partial<Record<Routes | ISidebarSection, boolean>> = {
  [ISidebarSection.producerPortal]: !flags.showProducerPortalFeature,
  [Routes.PRODUCER_PORTAL]: !flags.showProducerPortalFeature,
  [ISidebarSection.fulfillment]: !flags.showDeliveryFeature,
  [Routes.CREATE_DELIVERY_PACKAGE]: !flags.showDeliveryFeature,
  [Routes.DELIVERIES]: !flags.showDeliveryFeature,
  [Routes.EVENTS]: !flags.showEventFeature,
  [Routes.CREATE_EVENT]: !flags.showEventFeature,
  [Routes.CONFERENCES]: !flags.showConferenceFeature,
  [Routes.CREATE_NEW_CONFERENCE]: !flags.showConferenceFeature,
  [Routes.CREATE_ASSET_RECOMMENDATION]: !flags.showAssetRecommendationsFeature,
  [ISidebarSection.meetings]: !flags.showMeetingsFeature,
  [Routes.CALENDAR]: !flags.showMeetingsFeature,
  [Routes.LOCATIONS]: !flags.showMeetingsFeature,
  [Routes.COLLECTIONS]: !flags.showCollectionsFeature,
  [Routes.ACCESS_GROUPS]: !flags.showAccessGroupFeature,
  [Routes.CREATE_ACCESS_GROUP]: !flags.showAccessGroupFeature,
  [Routes.ACCESS_PRIVILEGES]: !flags.showAccessPrivilegeFeature,
  [Routes.CREATE_ACCESS_PRIVILEGE]: !flags.showAccessPrivilegeFeature,
  [Routes.GUILDS]: !flags.showGuildsFeature,
  [Routes.CREATE_GUILD]: !flags.showGuildsFeature,
  [Routes.CREATE_SMART_GROUP]: !flags.showSmartGroupFeature,
  [Routes.SMART_GROUP]: !flags.showSmartGroupFeature,
  [Routes.ORDER_MANAGEMENT]: !flags.showOrderManagementFeature,
  [Routes.CREATE_LOCALIZATION_ORDER]: !flags.showOrderManagementFeature,
};
