import { isAvailable, IShouldDisplayAssetActionFunction } from 'utils/actions/asset/actions-acl';

export const shouldDisplayDownload: IShouldDisplayAssetActionFunction = (action, index, user, entities) => {
  return (
    user?.role_name !== 'Sales Agent' &&
    entities?.length > 0 &&
    entities.every(
      (entity) =>
        isAvailable(entity) && entity.effective_permissions && entity.effective_permissions.indexOf('download') > -1,
    )
  );
};
