import React from 'react';
import { Row } from 'components/label-value-pair';
import { FormSelect } from 'helpers/form';
import { ILgtMobileAppSync } from './custom-types';

const inputStyle = { gridTemplateColumns: '3fr' };

export const CustomRow: React.FC<{ mobileAppSync?: ILgtMobileAppSync; editModeEnabled: boolean; form }> = ({
  mobileAppSync,
  editModeEnabled,
  form,
}) => (
  <Row
    label="App Presentation Mode Section"
    value={
      editModeEnabled ? (
        <FormSelect
          name="app_market_allocation_type"
          {...form.formData.app_market_allocation_type}
          {...form.handlers}
          style={inputStyle}
        />
      ) : (
        mobileAppSync?.app_market_allocation_type
      )
    }
  />
);
