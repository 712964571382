import React from 'react';
import { observer } from 'mobx-react-lite';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import {
  FormMultiSelect,
  FormNumericInput,
  FormInput,
  FormCountries,
  FormLanguageVersion,
  FieldListV2,
} from 'helpers/form';

import { createFieldSuggestion } from 'utils/apis/product';

import { IProductEditFormProps } from 'components/product-edit-sections/utils';

const suggestProducedBy = createFieldSuggestion('default_layer.meta.produced_by');

export const ProductEditProductionSection: React.FC<IProductEditFormProps> = observer(({ layerForm }) => {
  const { formData, handlers, values } = layerForm;

  return (
    <FormSectionWrapperV2 className="create_product__form" title="Production">
      <FormMultiSelect
        name="meta.produced_by"
        key="produced_by"
        label="Produced by"
        rawLabel
        placeholder="Search Producer Organizations..."
        {...formData.meta?.produced_by}
        {...handlers}
        fetchValues={suggestProducedBy}
        allowNewItems
      />
      <FormNumericInput
        name="meta.year_of_production"
        key="year_of_production"
        label="Year of Production"
        {...formData.meta?.year_of_production}
        {...handlers}
      />
      <FormInput
        name="meta.year_of_production_text"
        key="year_of_production_text"
        label="Year of Production (Text)"
        {...formData.meta?.year_of_production_text}
        {...handlers}
      />
      <FormNumericInput
        name="meta.duration"
        key="duration"
        label="Duration (Minutes)"
        max={60 * 24 * 365}
        {...formData.meta?.duration}
        {...handlers}
      />

      <FormInput
        name="meta.duration_text"
        key="duration_text"
        label="Duration (Text)"
        {...formData.meta?.duration_text}
        {...handlers}
      />
      <FieldListV2
        prefix="meta.language_versions"
        label="Language Versions"
        formValues={values}
        formHandlers={handlers}
        formData={formData}
        fieldProps={() => ({ large: true, formData, handlers })}
        component={FormLanguageVersion}
        className="product-edit__language-versions"
      />

      <FormCountries
        name="meta.country_of_origin_ids"
        key="country_of_origin_ids"
        label="Countries of Origin"
        placeholder="Select Countries of Origin"
        rawLabel
        {...formData.meta?.country_of_origin_ids}
        {...handlers}
      />
    </FormSectionWrapperV2>
  );
});

export default ProductEditProductionSection;
