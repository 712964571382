import { UppyFile } from '@uppy/core/types';

import { IAssetFile } from 'types';
import { flags } from 'utils/flags';
import { getAssetType } from 'utils/format-asset-type';

export function formatFileFields({ preview, id, type, name, ...fileData }: UppyFile): IAssetFile {
  type = flags.isMm3Assets ? getAssetType(type, fileData?.extension) : type;

  return {
    access_level: 'company',
    file_name: name,
    fileData,
    id,
    languages: [],
    name,
    permissions: [],
    preview,
    protection_levels: [],
    selected: true,
    status: 'available',
    watermark: '',
    type: type || '',
    classification: '',
    products: [],
    'layer.notes': '',
    sharable: true,
    'product.marketing_use': false,
    'meta.purpose': undefined,
    'meta.language_id': undefined,
    division_ids: [],
  };
}
