import { Model } from 'helpers/filters/types';
import { IFormMultiSelectOption } from 'helpers/form';

import { IQueryParams, ICustomField } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { parseToSearchParams } from 'utils/general';

export const fieldToOption = (field): IFormMultiSelectOption => ({
  value: field.value,
  label: field.value,
});

//TODO - the custom implementation fits with lgt client site.Consolidate it accordingly with client site when possible
export const queryCustomFields = (params: IQueryParams, purpose: string): Promise<ICustomField[]> =>
  chipmunk.run(async ({ action }) => {
    const values = params.ids || [];
    const newParams = params.q
      ? parseToSearchParams(params, [['purpose', 'eq', purpose]])
      : { search: { filters: [['purpose', 'eq', purpose]] } };

    try {
      const { objects } = await action(Model.CUSTOM_FIELD, 'search', {
        body: newParams,
        schema: 'id, purpose, value',
      });

      const foundValues = objects.filter((obj) => values.includes(obj.value));

      return !!params.ids ? foundValues : objects;
    } catch (error) {
      return [];
    }
  });
