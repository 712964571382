export const initialValues = {
  include_descendants: true,
  descendants_ids: [],
  subject: '',
  recipient_list: [],
  cc_list: [],
  bcc_list: [],
  generate_links_only: false,
  contact_selections: [],
  cc_contact_selections: [],
  bcc_contact_selections: [],
  send_emails: false,
  allowed_views: null,
  asset_selections: [],
  product_selections: [],
  requires_login: true,
};
export const allowAssetDownloadPower = 'mc/asset_recommendations_allow_download_option';
