export const EventSchema = `
  id,
  access_level,
  am_statistics_data,
  pm_statistics_data,
  description,
  deleted_at,
  created_at,
  updated_at,
  name,
  preview_image_id,
  preview_image {
    url
  },
  subevent,
  subevent_group_ids,
  users_count,
  is_timed_event,
  event_starts_at,
  event_ends_at,
  purpose,
  group_ids,
  approved_emails,
  allow_registered_user_access,
  show_event_countdown,
  owner_id,
  location,
  owner_organization_id,
`;

export const EventDetailsSchema = `
id,
access_level,
description,
am_statistics_data,
pm_statistics_data,
created_at,
updated_at,
name,
deleted_at,
preview_image_id,
preview_image {
  url
},
users_count,
event_starts_at,
event_ends_at,
purpose,
event_password,
settings,
registered_emails,
approved_emails,
allow_registered_user_access,
show_event_countdown,
location,
event_not_started_headline,
event_started_not_loggedin_headline,
event_started_loggedin_headline,
event_finished_headline,
event_not_started_introduction,
event_started_not_loggedin_introduction,
event_started_loggedin_introduction,
event_finished_introduction,
event_not_started_background_image_url,
event_not_started_featured_image_url,
event_started_not_loggedin_background_image_url,
event_started_not_loggedin_featured_image_url,
event_started_loggedin_background_image_url,
event_started_loggedin_featured_image_url,
event_finished_background_image_url,
event_finished_featured_image_url,
subevent,
subevent_group_ids,
is_timed_event,
group_ids,
owner_id,
owner,
owner_organization_id,
owner_organization { name },
`;
