import React from 'react';
import { uniqBy } from 'lodash';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';

import { IMobileAppSyncForm } from './custom-types';

export const MobileAppSyncFormSummary: React.FC<{ form: IMobileAppSyncForm }> = ({ form }) => {
  const { values } = form;
  const allAssetIds = uniqBy([...(values.asset_ids || []), ...(values.independentAssetIds || [])], (n) => n.toString());
  const assetIdsCount = allAssetIds.length || 0;

  const { include_descendants, product_ids = [], descendants_ids } = values || {};
  const productsCount = product_ids.length + (include_descendants ? descendants_ids?.length || 0 : 0);

  return (
    <FormSectionWrapperV2 padded={false} className="d-flex flex-column">
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        label="Name"
        value={values.name}
      />
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        label="Access level"
        value={values.access_level}
      />
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        label="App Presentation Mode Section"
        value={values.app_market_allocation_type}
      />
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        label="Products"
        value={productsCount}
      />
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        label="Assets"
        value={assetIdsCount}
      />
    </FormSectionWrapperV2>
  );
};
