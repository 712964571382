import React from 'react';
import { IFilters } from 'components/product-filters/types';
import { FilterSelect, IFiltersHandlers } from 'helpers/filters';
import { observer } from 'mobx-react-lite';
import { QueryDataProvider, queryDataExecutorCache } from 'helpers/data-provider/option-data-provider';
import { IResult } from 'utils/chipmunk';
import { ILanguage } from 'types';

import { queryLanguages } from 'utils/apis/language';
import { DataSectionStore } from 'store/data-section-store';

const languageKey = 'default_layer.meta.language_versions.language_id';

interface IAdditionalFiltersProps {
  filterValues: IFilters;
  filterHandlers: IFiltersHandlers<IFilters>;
  dataSectionStore: DataSectionStore;
}

const languageOptionsProvider = new QueryDataProvider(
  queryDataExecutorCache('um.language.search-cache', queryLanguages),
  (data: IResult<ILanguage>) => data.objects.map((l) => ({ value: l.id, label: l.name })),
);

export const LanguageSelector: React.FC<IAdditionalFiltersProps> = observer(
  ({ filterValues, filterHandlers, dataSectionStore }) => {
    const { searchStore } = dataSectionStore;

    return (
      <FilterSelect
        label="Language"
        name={languageKey}
        placeholder="Select Language"
        optionsProvider={languageOptionsProvider}
        filter={filterValues[languageKey]}
        onChange={filterHandlers.onChange}
        aggregations={searchStore.aggregationValues('language_id')}
      />
    );
  },
);
