import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { IContact } from 'types/contact';
import { ICreateWorkFlowPayload } from 'utils/actions/types';
import { formatFullName } from 'utils/general';

export const getInitialValues = (
  user: IContact | null,
): Partial<ICreateWorkFlowPayload<McGenericRecommendationProduct>> => {
  return {
    include_descendants: true,
    descendants_ids: [],
    subject: 'You have received screeners from Lionsgate Television Distribution!',
    recipient_list: [],
    cc_list: [],
    bcc_list: [],
    generate_links_only: false,
    contact_selections: [],
    cc_contact_selections: [],
    bcc_contact_selections: [],
    send_emails: false,
    allowed_views: null,
    type: 'Recommendation::Product',
    product_selections: [],
    include_future_episodes_of_product_ids: [],
    requires_login: true,
    message: `This content was recommended to you by ${formatFullName(user, false)}.
   
-Team Lionsgate`,
  };
};
