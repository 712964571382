import { IMobileAppSyncFormData } from './custom-types';

export const initialValues: IMobileAppSyncFormData = {
  name: '',
  access_level: 'private',
  product_ids: [],
  asset_ids: [],
  descendants_ids: [],
  include_descendants: false,
  app_market_allocation_type: '',
  independentAssetIds: [],
};

export const relatedProductType = null;
export const relatedProductTooltipLabel =
  "Includes the selected products' related products and, if ticked above, their descendants";
