import { useCallback, useEffect } from 'react';
import { get } from 'lodash';

import { useStore } from 'store';
import { Model } from 'helpers/filters/types';
import { IUseFormReturn, useForm } from 'helpers/form';
import { ILgtMobileAppSync, IMobileSyncEditFormFields } from './custom-types';
import { ItemId } from 'types/utility';
import { IResult, chipmunk } from 'utils/chipmunk';

export const editMobileAppSync = async (
  groupId: ItemId,
  newGroup?: IMobileSyncEditFormFields,
  schema?: string,
): Promise<IResult> => {
  return chipmunk.action(Model.MOBILE_APP_SYNC, 'member.update', {
    params: { group_ids: groupId },
    body: {
      id: groupId,
      name: newGroup?.name,
      app_market_allocation_type: newGroup?.app_market_allocation_type,
    },
    schema,
  });
};

export const useEditMobileSync = ({
  mobileAppSync,
  setEditModeEnabled,
  refreshMobileAppSync,
}: {
  mobileAppSync?: ILgtMobileAppSync;
  setEditModeEnabled: (editMode: boolean, updateParam?: boolean) => void;
  refreshMobileAppSync: () => Promise<void>;
}): IUseFormReturn<IMobileSyncEditFormFields> => {
  const { toastStore } = useStore();
  const { name, id: mobileSyncId, app_market_allocation_type } = mobileAppSync || {};

  const handleSubmit = useCallback(
    async (values: IMobileSyncEditFormFields): Promise<void> => {
      try {
        if (mobileSyncId) {
          toastStore.info('Saving');
          await editMobileAppSync(mobileSyncId, values);
          toastStore.clearAll();
          toastStore.success('Saved', 1000);
          setEditModeEnabled(false);
          refreshMobileAppSync();
        }
      } catch (e) {
        toastStore.clearAll();
        toastStore.error(`Error while saving: ${get(e, 'object.description', 'Unknown error')}`);
      }
    },
    [mobileSyncId, refreshMobileAppSync, setEditModeEnabled, toastStore],
  );

  const editForm = useForm<IMobileSyncEditFormFields>(
    { name: name || '', app_market_allocation_type: app_market_allocation_type || '' },
    Model.MOBILE_APP_SYNC,
    handleSubmit,
  );
  const { onChange } = editForm.handlers;
  useEffect(() => {
    if (!app_market_allocation_type) {
      return;
    }
    onChange({ app_market_allocation_type });
  }, [app_market_allocation_type, onChange]);
  return editForm;
};
