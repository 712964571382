export const mobileAppSyncSchema = `
id,
access_level,
am_statistics_data,
pm_statistics_data,
created_at,
updated_at,
expires_at,
deleted_at,
scheduled_at,
name,
preview_image {
  url
},
preview_image_id,
users_count,
owner_id,
owner,
owner_organization_id,
settings {
  protection_levels
},
description,
app_market_allocation_type,
`;
export const mobileAppSyncDetailsSchema = `${mobileAppSyncSchema}, owner_organization`;
