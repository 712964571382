import { IProduct, IProductLayer } from 'types';

export const productLayerInitialValues = {
  meta: {
    category_ids: [],
    synopsis: undefined,
    logline: undefined,
    product_state: undefined,
    audience_recommendations: [],
    year_of_production: undefined,
    duration: undefined,
    duration_text: undefined,
    resolution: undefined,
    language_versions: [],
  },
  language_id: 'en',
  title: undefined,
  type: 'Layer3::MotionPicture::Standard',
} as unknown as IProductLayer;

export const productInitialValues = {
  access_level: 'company',
  external_reference: undefined,
  parent_id: undefined,
  sequence_number: undefined,
  type: undefined,
  permissions: [],
  division_ids: [],
} as unknown as IProduct;
