import React from 'react';
import { IFilters } from 'components/product-filters/types';
import { IFiltersHandlers } from 'helpers/filters';

export const additionalFilters = {};

interface IAdditionalFiltersProps {
  filterValues: IFilters;
  filterHandlers: IFiltersHandlers<IFilters>;
}

export const AdditionalFiltersCmp: React.FC<IAdditionalFiltersProps> = () => <></>;
