import React from 'react';
import { observer } from 'mobx-react-lite';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';

import { useCountryNames, useLanguageVersionInfo } from 'utils/hooks';

import { CategoriesInfo } from 'utils/ui';

import ScrollWrapper from 'components/scroll-wrapper';
import { PmGenericProduct3MotionPicture, PmLgtLayer3MotionPictureStandard } from '@mediafellows/mm3-types';

import 'pages/product-details/product-metadata-tab/style.scss';

interface IProductMetadataTabProps {
  product?: PmGenericProduct3MotionPicture & { default_layer: PmLgtLayer3MotionPictureStandard };
}

export const ProductMetadataTab: React.FC<IProductMetadataTabProps> = observer(({ product }) => {
  const { default_layer, external_reference, internal_reference } = product || {};
  const { title, meta } = default_layer || {};

  const {
    brand_name,
    category_ids,
    year_of_production,
    synopsis,
    produced_by,
    year_of_production_text,
    product_type,
    broadcaster,
    additional_information,
    duration,
    duration_text,
    language_versions,
    subtitle,
    country_of_origin_ids,
    web_page,
    tags,
    copyright,
    copyright_notice,
    imdb_id,
    identifier_archive_number,
    rights_holder,
    original_release_date,
    network,
    logline,
    long_synopsis,
    gross_box_office,
    rating,
  } = meta || {};
  const languageVersionsInfo = useLanguageVersionInfo(language_versions);

  const countriesOfOrigin = useCountryNames(country_of_origin_ids);

  return (
    <div className="product-metadata-tab h-100">
      <ScrollWrapper>
        <div className="product-metadata-tab__container">
          <div>
            <InfoBoxV2 className="product-metadata-tab__second-section" wrapperClassName="py-3" title="About">
              <Row label="Title" value={title} />
              <Row label="Subtitle" value={subtitle} />
              <Row label="Genres" value={<CategoriesInfo category_ids={category_ids} expanded />} />
              <Row html label="Logline" value={logline} />
              <Row html label="Synopsis" value={synopsis} />
              <Row html label="Long Synopsis" value={long_synopsis} />
              <Row html label="Additional Information" value={additional_information} />
            </InfoBoxV2>

            <InfoBoxV2 className="product-metadata-tab__second-section" title="Production" wrapperClassName="pb-3">
              <Row label="Produced by" rawLabel value={produced_by} />
              <Row label="Year of Production" rawLabel value={year_of_production} />
              <Row label="Year of Production (Text)" rawLabel value={year_of_production_text} />
              <Row label="Duration (Minutes)" rawLabel value={duration} />
              <Row label="Duration (Text)" rawLabel value={duration_text} />
              <Row label="Language Versions" value={languageVersionsInfo} />
              <Row label="Countries of Origin" rawLabel value={countriesOfOrigin} />
            </InfoBoxV2>
          </div>
          <div>
            <InfoBoxV2 className="product-metadata-tab__second-section" title="DISTRIBUTION" wrapperClassName="py-3">
              <Row label="Broadcaster" rawLabel value={broadcaster} />
              <Row label="Original Release Date" rawLabel value={original_release_date} />
              <Row label="Network" value={network} />
              <Row label="Box Office" value={gross_box_office} />
              <Row label="Rating" html value={rating} />
            </InfoBoxV2>

            <InfoBoxV2 className="product-metadata-tab__second-section" title="Legal" wrapperClassName="pb-3">
              <Row label="Copyright" rawLabel value={copyright} />
              <Row label="Copyright Notice" rawLabel value={copyright_notice} />
              <Row label="Rights Holder" rawLabel value={rights_holder} />
            </InfoBoxV2>

            <InfoBoxV2 className="product-metadata-tab__second-section" title="Identifiers" wrapperClassName="pb-3">
              <Row label="Internal Reference" value={internal_reference || '[n/a]'} />
              <Row label="External Reference" value={external_reference || '[n/a]'} />
              <Row label="Product Type" value={product_type || '[n/a]'} />
              <Row label="Brand Name" value={brand_name || '[n/a]'} />
              <Row label="DXNO" value={identifier_archive_number || '[n/a]'} />
              <Row label="web_page" value={web_page || '[n/a]'} />
              <Row label="Tags" value={tags} />
              <Row label="IMDb ID" rawLabel value={imdb_id} fallback="[n/a]" />
            </InfoBoxV2>
          </div>
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default ProductMetadataTab;
