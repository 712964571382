import React from 'react';
import { Row } from 'components/label-value-pair';
import { IMobileAppSync } from 'types';

interface ILgtMobileAppSync extends IMobileAppSync {
  app_market_allocation_type: string;
}
export const CustomRow: React.FC<{ mobileAppSync: ILgtMobileAppSync }> = ({ mobileAppSync }) => (
  <Row label="App Presentation Mode Section" value={mobileAppSync.app_market_allocation_type} />
);
