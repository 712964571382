const index = {
  siteTitle: 'LGT',
  header: {
    logo: {
      alt: 'LGT Logo',
      text: 'LGT MediaStore',
    },
  },
};

export default index;
