import React, { useCallback } from 'react';

import { FormRemoteSelect, IRemoteSelectorFetcher, FormSegment, FormHtmlInput, FormImage } from 'helpers/form';
import { FormSectionWrapper } from 'components/form-section-wrapper';
import { queryParentEvents } from 'utils/apis/events';

import { IStepGeneralInfo } from './types';

const typeSegments = [
  { value: 'mainevent', label: 'Main Event' },
  { value: 'subevent', label: 'Sub-Event' },
];

const StepGeneralInfoSecondColumn: React.FC<IStepGeneralInfo> = (props) => {
  const { form, editMode = false } = props;

  const eventFetcher: IRemoteSelectorFetcher = useCallback(async (q, id) => {
    return queryParentEvents({ q, ...(id ? { ids: [id] } : {}) });
  }, []);

  const { onChange } = form.handlers;

  const onSubEventChange = useCallback(
    ({ ...values }) => {
      if (!values.eventType) {
        values.group_parent_group_id = null;
      } else {
        values.event_password = '';
      }

      onChange(values);
    },
    [onChange],
  );

  return (
    <FormSectionWrapper className="create-wizard__inner-section" gridTemplateColumns="1fr">
      <FormSegment
        name="eventType"
        label="Type"
        segments={typeSegments}
        {...form.formData.eventType}
        {...form.handlers}
        onChange={onSubEventChange}
        disabled={editMode}
      />

      <FormRemoteSelect
        name="group_parent_group_id"
        label="Main Event"
        large
        fetchOptions={eventFetcher}
        {...form.formData.group_parent_group_id}
        {...form.handlers}
        disabled={editMode || form.values.eventType === 'mainevent' || !form.values.eventType}
      />

      <FormImage
        name="group_preview_image_url"
        label="Preview Image"
        {...form.formData.group_preview_image_url}
        {...form.handlers}
      />
      <FormHtmlInput name="description" label="Description" large {...form.formData.description} {...form.handlers} />
    </FormSectionWrapper>
  );
};

export { StepGeneralInfoSecondColumn };
